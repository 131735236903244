import React, { useState, useEffect, useRef } from 'react';
import ReCAPTCHA from "react-google-recaptcha";


function GetNotesLink() {
  const [status, setStatus] = useState('ready')
  const [error, setError] = useState(null)
  const [email, setEmail] = useState('')
  const [title, setTitle] = useState('')
  const [gdocLink, setGDocLink ] = useState(null)
  const [captcha, setCaptcha] = useState(false)

  let email_ref = useRef(null)
  let title_ref = useRef(null)

  async function getLink() {
    let post_data = {
      'captcha_token': captcha,
      //'email': email_ref.current.value,
      //'title': title_ref.current.value,
      'title': title,
      'email': email,
    }
    const api_uri = process.env.REACT_APP_API_URI
    //const api_url = 'http://localhost:8080/api/v1/create_note'
    //const api_url = 'http://localhost:80/api/v1/create_note'
    const api_url = api_uri + '/api/v1/create_note'
    /*
              try { // statements to try
            monthName = getMonthName(myMonth); // function could throw exception
          }
          catch (e) {
            monthName = 'unknown';
            logMyErrors(e); // pass exception object to error handler -> your own function
      }
    */
    let response = null
    try {
      response = await fetch(
        //'/api/v1/create_note',
        api_url,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json; charset=utf-8",
          },
          body: JSON.stringify(post_data)
        }
      )
    } catch(e) {
      console.log(e)
      setError("Something went wrong. Please try again later.")
      setStatus("ready")
      return
    }

    if (!response.ok) {
      if (response.status === 400 ) {
        const json_data = await response.json()
        if (json_data.display) {
          setError(json_data.error)
          setStatus("ready")
          return
        }
      }
      setError("Something went wrong. Please try again later.")
      setStatus("ready")
      return
    }

    const json_data = await response.json()

    setGDocLink(json_data.doc_url)
    setStatus("link_ready")
  }

  function getLinkHandler() {
    if ( !captcha ) {
      return
    }
    if ( title.length < 1 ) {
      setError("Invalid title")
      return
    }
    if ( email.length < 3 ) {
      setError("Invalid email")
      return
    }
    setStatus("loading")
    getLink()
  }

  function captchaVerified(token) {
    setCaptcha(token)
  }

  if ( status === "loading" ) {
    return (
      <div style={{ 'width': '22em'}}>
        <br/><br/>
        Loading ... This takes a few seconds. Please Wait.
      </div>
    )
  }

  // <a target="_blank" rel="noopener noreferrer" href="http://your_url_here.html">Link</a>

  if ( status === "link_ready" ) {
    return (
      <div style={{ 'width': '22em'}}>
        <br/><br/>
        You can type your notes in this google doc: <br/>
        <a href={gdocLink} target="_blank" rel="noopener noreferrer" >
          Google Doc
        </a> <br/> <br/>

        The link to the doc has been emailed to you. <br/>
        This google doc will be deleted in 7 days.
      </div>
    )
  }

  // let captcha_message = null
  // if ( captcha ) {
  //   captcha_message = (
  //     <div> Captcah Sucess. Token: {captcha} </div>
  //   )
  // }

  /*
  <input type="text" value={this.state.value} onChange={this.handleChange} />
  onChange={ (e) => this.onInputChange('email', e.target.value) }

  handleChange(event) {
    this.setState({value: event.target.value});
  }


  */

  // <input type="text" placeholder="Note Title" ref={title_ref} /> <br></br>
  // <input type="text" placeholder="Email" ref={email_ref} /> <br></br>


  return (
    <div style={{ 'width': '25em'}}>
      <span style={ {color: 'red', } }> {error} </span> <br/><br/>
      <span style={ {'fontSize': '1em', } }>
        Email notes to yourself, without logins or passwords.
      </span>
      <br/><br/>
      <input
        type="text" placeholder="Note Title"
        value={title}
        style={{ 'width': '20em', 'font-size': '1.0em', 'margin-bottom': '0.5em'}}
        onChange={ (e) => setTitle(e.target.value)} />
      <br/>
      <input
        type="text" placeholder="Email"
        value={email}
        style={{ 'width': '20em', 'font-size': '1.0em', 'margin-bottom': '0.5em'}}
        onChange={ (e) => setEmail(e.target.value)} />
      <br/>
      <ReCAPTCHA
        sitekey="6LfdKsMUAAAAAP2_yOX6AWlEOx8W9aC90y-svBTF"
        onChange={captchaVerified}
      /> <br/>
      <button
        style={{
          'font-size': '1.5em', 'margin-bottom': '0.5em',
          'cursor': 'pointer',
        }}
        onClick={getLinkHandler}> Start Typing Notes
      </button>
    </div>
  );
}

export default GetNotesLink;