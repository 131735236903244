import React, { useState, useEffect, useRef } from 'react';
import GetNotesLink from './GetNotesLink'


function EmnApp() {
	// <img src={"/notepad.svg"} width='40' height='40'/>
	// display:inline-block and vertical-align:middle
	return (
		<div style={{
			'display': 'flex',
			'flexDirection': 'column',
			'alignItems': 'center',
		}} >
			<div style={{ 'width': '25em', 'paddingTop': '1em'}}>
				<img src={"/notepad.svg"}
					style={{
						'display': 'inline-block',
						'vertical-align': 'middle',
						'width': '3em',
						'height': '3em',
						'marginRight': '1em'
					}}
				/>
				<span style={{ 'fontSize': '2em', 'vertical-align': 'middle', }} >
					Email My Notes
				</span>
			</div>
			<div style={{ 'width': '25em', 'height': '21em'}}>
				<GetNotesLink/>
			</div>
			<div style={{ 'width': '25em', 'paddingBottom': '5em'}}>
				<strong>Why?</strong><br/>
				Send yourself notes from an "untrusted" computer.<br/>
				Examples: corporate laptop, library computer, friends computer<br/>
				<br/>
				<strong>How?</strong><br/>
				Enter title, email, and complete captcha. You will get a link to
				a google doc that you can edit without logging in. The link will also be
				emailed to you.<br/>
				The google doc will be automatically deleted in about 7 days<br/>
				<br/>
				<strong>Why did I get an email from emailmynotes.com?</strong><br/>
				If you didn't create the note, then you should delete the message.
				It was probably created by a spammer (or someone who knows your email). <br/>
				<br/>
				<strong>How do you stop spammers?</strong><br/>
				CAPTCHA and other throttling. You can also unsubscribe from any future emails.<br/>
				<br/>
				<strong>Why should I trust you with my email?</strong><br/>
				I built this as a hack for a personal use case. Its pretty cheap for me to run. I am not going to sell your email.<br/>
				<br/>
				<strong>I have questions or comments</strong><br/>
				<a href="https://docs.google.com/forms/d/e/1FAIpQLSeaFJwXHXBsK2zNZxRDJsAt1JzPtwQg7hkOHJDw7EW68BQNfA/viewform"
				   target="_blank" rel="noopener noreferrer" >
          			Click here
        		</a><br/>
				<br/>
			</div>
		</div>
	)
}

export default EmnApp;

